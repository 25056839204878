import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { EmbedContext } from '../Frontend/UI/EmbedProvider';
import { useLocale } from './locale';
import { Button } from './UI/Button';
import { Card } from './UI/Card';

interface CookieConsentContextValue {
  cookieConsent: boolean | null;
  acceptCookies: () => void;
  denyCookies: () => void;
}

export const CookieConsentContext = React.createContext<CookieConsentContextValue>({
  cookieConsent: null,
  acceptCookies: () => null,
  denyCookies: () => null,
});

interface CookieConsentProviderProps {
  children: ReactNode;
}

const updateCookie = (value: boolean | null) => {
  if (value) {
    // Remember choice for 365 days
    document.cookie = 'cookie_consent=1;path=/;Max-Age=31536000';
  } else if (value === false) {
    // Remember choice for current session
    document.cookie = 'cookie_consent=0;path=/';
  } else {
    document.cookie = 'cookie_consent=;path=/;Max-Age=0';
  }
};

export const getCookieConsent = () => {
  const cookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)cookie_consent\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );

  return cookie === '1' ? true : (cookie === '0' ? false : null);
};

const CookieConsentProvider = ({ children }: CookieConsentProviderProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { isEmbedded } = useContext(EmbedContext);

  const [cookieConsent, setCookieConsent] = useState(getCookieConsent);

  const acceptCookies = useCallback(() => {
    updateCookie(true);
    setCookieConsent(true);
  }, []);

  const denyCookies = useCallback(() => {
    updateCookie(false);
    setCookieConsent(false);
  }, []);

  // Update Analytics consent.
  useEffect(() => {
    window.gtag?.('consent', 'update', {
      ad_storage: cookieConsent ? 'granted' : 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'granted',
    });
  }, [cookieConsent]);

  return (
    <CookieConsentContext.Provider value={{ cookieConsent, acceptCookies, denyCookies }}>
      {children}
      {!isEmbedded && cookieConsent === null && (
        <Card
          spacing="sm"
          className="!fixed left-4 bottom-4 right-4 z-[80] sm:left-8 sm:bottom-8 sm:right-0 max-w-md space-y-3 drop-shadow-xl"
        >
          <div className="text-xs sm:text-sm">
            <Trans i18nKey="shared:cookies.description">
              <a href={`https://supporta.com/${locale}/privacy`} target="_blank" rel="noopener noreferrer" />
            </Trans>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <Button onClick={denyCookies} variant="secondary" size="sm">
              {t('shared:cookies.reject')}
            </Button>
            <Button onClick={acceptCookies} size="sm">
              {t('shared:cookies.accept')}
            </Button>
          </div>
        </Card>
      )}
    </CookieConsentContext.Provider>
  );
};

export default CookieConsentProvider;
