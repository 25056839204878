import { router } from '@inertiajs/react';
import { useEffect } from 'react';

const searchParams = new URLSearchParams(window.location.search);

const getInitialUrl = () => {
  return sessionStorage?.getItem('initial_url')
    || searchParams.get('initial_url')
    || window.location.href;
};

const getFinalUrl = () => {
  return sessionStorage?.getItem('final_url')
    || searchParams.get('final_url');
};

export const storeTrackingUrls = () => {
  sessionStorage?.setItem('initial_url', getInitialUrl());

  const finalUrl = getFinalUrl();

  if (finalUrl) {
    sessionStorage?.setItem('final_url', finalUrl);
  }
};

export const getTrackingData = () => {
  return {
    initial_url: getInitialUrl(),
    final_url: getFinalUrl(),
  };
};

export const useTrackingUrls = () => {
  useEffect(() => {
    storeTrackingUrls();

    // Strip tracking data from the current URL.
    setTimeout(() => {
      // For some reason, calling router.reload({ replace: true }) outside this timeout function,
      // causes an actual page reload instead of history replace.
      const url = new URL(window.location.href);

      if (url.searchParams.has('initial_url') || url.searchParams.has('final_url')) {
        router.reload({ replace: true, data: {
          initial_url: undefined,
          final_url: undefined,
        } });
      }
    }, 0);
  }, [window.location.href]);
};
