import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

type HeaderProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function Header({
  className,
  children,
  ...props
}: HeaderProps) {

  return (
    <div
      className={classNames(
        'border-b border-slate-200',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
