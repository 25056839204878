
import classNames from 'classnames';
import { Image, Users } from 'react-feather';
import { Trans } from 'react-i18next';

import { darkenColor, getAccessibleColor } from '../../Shared/helpers';
import { useLocale } from '../../Shared/locale';
import { Campaign, CampaignType, Charity, Project } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { ProgressBar } from '../../Shared/UI/ProgressBar';
import { defaultColor } from '../../Shared/useCharityStyle';

export default function CampaignCard({
  campaign,
  charity,
  showProject = false,
  reload = false,
  onClick,
}: {
  campaign: Campaign & {
    project: Project;
  };
  charity: Charity;
  showProject?: boolean;
  reload?: boolean;
  onClick?: () => void;
}) {
  const { formatCurrency } = useLocale();

  const thumbnailUrl = campaign.thumbnail_url || campaign.project.thumbnail_url || charity.thumbnail_url;

  const brandColor = charity.brand_color || defaultColor;
  const complimentaryColor = getAccessibleColor(darkenColor(brandColor, 0.25));

  return (
    <Card
      spacing="none"
      link={{
        onClick,
        href: onClick ? undefined : campaign.url,
        ariaLabel: campaign.title,
        reload,
      }}
      border
      className="grid-cols-[1fr_3fr] grid"
    >
      <div className="pt-2 pb-2 pl-2">
        {thumbnailUrl && (
          <div className="h-full rounded-md">
            <img src={thumbnailUrl} alt={campaign.title} className="object-cover w-full h-full rounded-md" />
          </div>
        )}
        {!thumbnailUrl && (
          <div
            className={classNames(
              'relative flex items-center justify-center h-full rounded-lg',
            )}
          >
            <div className="absolute inset-0 rounded-lg opacity-10 z-[2]"
              style={{ background: brandColor }}
            />
            {charity.logo_url && (
              <div className="w-1/2 h-1/2 z-1">
                <img src={charity.logo_url} alt={campaign.title} className="w-full h-full object-contain" />
              </div>
            )}
            {!charity.logo_url && (
              <Icon className="text-4xl text-black opacity-10 z-[2]">
                <Image strokeWidth={1} />
              </Icon>
            )}
          </div>
        )}
      </div>
      <div className="min-w-0 p-4 space-y-2">
        <div className="flex space-x-2">
          {campaign.type === CampaignType.Team && (
            <div
              className="rounded-full w-5 h-5 text-white text-xs flex items-center justify-center my-0.5 shrink-0"
              style={{
                background: brandColor,
                color: complimentaryColor,
              }}
            >
              <Icon>
                <Users />
              </Icon>
            </div>
          )}
          <h3 className="overflow-hidden whitespace-nowrap text-ellipsis">
            {campaign.title}
          </h3>
        </div>

        <ProgressBar
          current={campaign.funds_raised}
          target={campaign.fundraising_target}
          brandColor={charity.brand_color}
        />

        <div>
          <Trans
            i18nKey="frontend:progress_raised"
            values={{ amount: formatCurrency(campaign.funds_raised) }}
          >
            <strong className="font-bold text-slate-900" />
          </Trans>
        </div>

        {showProject && (
          <div className="overflow-hidden text-sm text-slate-500 whitespace-nowrap text-ellipsis">
            {campaign.project.title}
          </div>
        )}
      </div>
    </Card>
  );
}
