import { Head, usePage } from '@inertiajs/react';
import { ReactNode } from 'react';

import CookieConsentProvider from '../../Shared/CookieConsentProvider';
import { TranslationsProvider } from '../../Shared/locale';
import NotificationProvider from '../../Shared/Notifier/NotificationProvider';
import { useTrackingUrls } from '../../Shared/tracking';
import { PageProps } from '../types';
import EmbedProvider from './EmbedProvider';

export default function Layout({
  children,
}: {
  children?: ReactNode;
}) {
  const { props: { document } } = usePage<PageProps>();

  useTrackingUrls();

  return (
    <EmbedProvider>
      <TranslationsProvider>
        <CookieConsentProvider>
          <NotificationProvider>
            {document && <Head title={document.title} />}
            {children}
          </NotificationProvider>
        </CookieConsentProvider>
      </TranslationsProvider>
    </EmbedProvider>
  );
}
